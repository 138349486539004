import logo from "./images/logo.webp";
import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import trackVisitor from "./utilities/tracker";
import ResultChart from "./result_chart";

const momenttz = require("moment-timezone");

function App() {
  const currentTime = moment().format("HH:mm");
  const [data, setData] = useState([]); // State to store data fetched from backend
  const [datagame, setDataFor] = useState([]); // State to store processed data for display
  var currentDate = moment(new Date()).tz("Asia/Kolkata").format("lll");
  useEffect(() => {
    trackVisitor();
  }, []);

  var currentDate = moment(new Date()).tz("Asia/Kolkata").format("YYYY-MM-DD");
  var prevDate = moment(new Date())
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);

  useEffect(() => {
    if (data?.length > 0) {
      // Convert current time to a moment object for comparison
      const currentMoment = moment(currentTime, "HH:mm");

      // Process and filter the data
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;

        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "WAIT",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });

      // Sort the processed data by open_time
      const sortedProcessedData = processedData.sort((a, b) => {
        return a.itemTime.diff(b.itemTime);
      });

      // Separate records into those with available results and those with "wait"
      const availableResults = sortedProcessedData.filter(
        (item) => item.isAvailable
      );
      const upcomingRecords = sortedProcessedData.filter(
        (item) => !item.isAvailable
      );

      // Determine the records to display
      let recordsToDisplay = [];

      if (availableResults.length > 0) {
        // Show available results and include records up to the next upcoming record
        recordsToDisplay = [...availableResults];

        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        // No available results, show up to 3 upcoming records with "wait"
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }

      // Ensure only 3 records are shown
      if (recordsToDisplay.length > 3) {
        // Remove the oldest record if more than 3 records are present
        recordsToDisplay = recordsToDisplay.slice(-3);
      }

      // Update state with the processed and limited data
      setDataFor(recordsToDisplay);

      // Debugging log
    }
  }, [data, currentTime]);

  // scroll navigation
  function pageScroll() {
    const daySection = document.getElementById("dayResult");
    const tableSection = document.getElementById("monthTable");
    if (daySection) {
      daySection.scrollIntoView({ behavior: "smooth" });
    }
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: "smooth" });
    }
  }
  return (
    <div className="App">
      <div class="navbar1 text-center col-12">
        <ul class="navbar-nav nav row">
          {/* <ul class="navbar-navv row"> */}
          <li class="nav-item col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex justify-content-evenly">
            <a
              onClick={(e) => {
                e.preventDefault();
                pageScroll();
              }}
              class="nav-link col-md-6 col-sm-12"
              aria-current="page"
              href=""
            >
              <div type="" class=" nav-btn ">
                Satta Result
              </div>
            </a>
          </li>
          <li class="nav-item col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex justify-content-evenly ">
            <a class="nav-link col-md-6 col-sm-12" href="">
              <div type="" class="nav-btn ">
                Desawar Result
              </div>
            </a>
          </li>
          <li class="nav-item col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex justify-content-evenly ">
            <a
              onClick={(e) => {
                e.preventDefault();
                pageScroll();
              }}
              class="nav-link col-md-6 col-sm-12"
              href=""
            >
              <div type="" class="nav-btn ">
                Satta chart
              </div>
            </a>
          </li>
        </ul>

        <h1 className="main-heading text-center w-100 my-0">
          <a href="/">
            <img src={logo} />
          </a>
        </h1>
      </div>
      <marquee>
        Satta king, Sattaking, Desawar result, Satta result, Satta king result,
        Satta king live, Satta king online , Gali result, Faridabad result,
        Gaziyabad result, Satta king chart, Desawar record chart, Gali record
        chart, Faridabad record chart, Satta King 2024, Gaziyabad record chart,
        Dishawar Satta King.
      </marquee>

      <div className="text-center search_result">
        <h3>
          Satta King | Gali Result | Satta Result | Desawar Result | सट्टा किंग
          | Satta King Online Result 2024
        </h3>
        <p className="pera-1 mt-3 fw-bold mb-0">:: Live Satta King Result ::</p>
        <p className="pera-2 mb-1">{currentDate}</p>
        <div className="banner bg-success text-white p-3">
          {datagame.map((game, index) => (
            <div key={index} className="game mb-4">
              <h5>{game.gameName}</h5>
              {/* <p>{`Time { ${game.time} }`}</p> */}
              <p className="blinking-text">{game?.result || "--"}</p>
            </div>
          ))}
        </div>

        <ResultChart dayGameData={data} />
      </div>
    </div>
  );
}

export default App;
