import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
const ResultChart = ({ dayGameData }) => {
  const [data, setGameData] = useState([]);
  const location = useLocation();
  const currentYear = moment().year()
  const currentTime = moment().format("HH:mm");
  const currentDate = moment().format("YYYY-MM-DD");

  useEffect(() => {
    setGameData(dayGameData);
  }, [dayGameData]);

  // based on current date and time get data
  const getTodayResult = (gameData) => {
    const itemTime = moment(gameData.open_time, "HH:mm");
    const currentMoment = moment(currentTime, "HH:mm");

    if (gameData?.curr_date?.date === currentDate) {
      return currentMoment.isSameOrAfter(itemTime)
        ? gameData?.curr_date?.result || ""
        : "";
    }
    return "";
  };

  const isPrivacy = location.pathname.includes("/privacy-policy");
  const isDisclaimer = location.pathname.includes("/disclaimer");
  const isAbout = location.pathname.includes("/about-us");
  return (
    <div id='dayResult'>
      {!(isPrivacy || isDisclaimer || isAbout) && (
        <div className="col-12 pt-5 result-chart-container">
          <h3 className="chart-heading">Satta King {currentYear } Chart</h3>
          <div className="row">
            {data.map((gameData, index) => (
              <div key={index} className="col-md-6 col-sm-12">
                <div className="col-lg-12">
                  <h6 className="game-name">{gameData?.game_name}</h6>
                  <p className="mb-0 fs-6 textColor game-time">{`Time { ${gameData.open_time} }`}</p>
                  <div className="d-flex align-items-end text-center">
                    <div className="col-6 float-star">
                      <span class="btn">
                        {gameData?.prev_date?.result || "null"}
                      </span>
                    </div>
                    <div className="col-6 float-end">
                      <span class="btn">{getTodayResult(gameData)}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultChart;
