import React from 'react';
import { useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };
    return (
        <div>
            <div className="footer-container">
                <h2>SATTA KING SATTA RESULT</h2>
                <p>satta matka, satta, satta king up, kalyan matka, matka result, satta king result, सट्टा किंग, delhi satta king, disawar satta, disawar, satta bajar, gali satta result, satta king 2023, black satta king, satta king chart, desawar, satta king desawar, desawar satta satta king fast, satta king 2024, satta king ghaziabad, satta king 786, satta king result, satta king online, satta king faridabad, shri ganesh satta king, satta king result chart, taj satta king, satta king kashipur, satta king desawar 2019, satta king 2018, satta result, satta company, satta king 2024, satta king up, satta king chart, satta king gali, satta king 2018 chart, satta king chart 2019, gali satta chart, satta king 2019 chart, satta king desawar, fast satta result, gali desawar result</p>
            </div>

            <div className='footer-buttons'>
                <div className='d-flex button justify-content-center'>
                    <span className='p-1' style={{cursor:'pointer'}} onClick={() => handleNavigation('/')}><p>Satta King</p></span>
                    <span className='p-1' style={{cursor:'pointer'}} onClick={() => handleNavigation('/about-us')}><p>About</p></span>
                    <span className='p-1' style={{cursor:'pointer'}} onClick={() => handleNavigation('/')}><p>Record</p></span>
                </div>
                <div className='d-flex button justify-content-center'>
                    <span className='p-1' style={{cursor:'pointer'}} onClick={() => handleNavigation('/privacy-policy')}><p>Privacy Policy</p></span>
                    <span className='p-1' style={{cursor:'pointer'}} onClick={() => handleNavigation('/disclaimer')}><p>Disclaimer</p></span>
                    <span className='p-1' style={{cursor:'pointer'}} onClick={() => handleNavigation('/')}><p>Sitemap</p></span>
                </div>
            </div>
        </div>
    );
};

export default Footer;
