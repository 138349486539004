import React from 'react';
import { Routes, Route } from 'react-router-dom';
import About from './about';
import Privacy from './privacy';
import Disclaimer from './disclamier';

const FooterButtonPage = () => {
    return (
        <div className='pt-4'>
            <Routes>
                <Route path="/about-us" element={<About />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
            </Routes>
        </div>
    );
};

export default FooterButtonPage;
