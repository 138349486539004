import React from 'react';

const About = () => {
    return (
        <div className='about text-center'>
            <h2 className='fw-bold'>About us</h2>
            <p>Gali Result is a website that provides Satta King results. We provide the fastest Satta King result as our result is directly published by Satta companies employees. We display only the results of the Satta King games and provide you information related to Satta King. We do not hold any legal responsibilities of the information shown on our website and do not take any responsibility for any transaction made on the name of our site. We only provide information and results and we're focused on that. For any queries email us at info@galiresult.com.</p>
        </div>
    );
};

export default About;
